<template>
  <b-card>
    <!-- <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm"> -->

    <!-- Cabeçalho com dados da aula e botões de Arquivar e Editar aula -->
    <b-row>
      <b-col cols="2" xl="1" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="require('@/assets/images/pages/classes.svg')" class="bg-avatar bg-color-avatar" size="80px"
            rounded />
        </div>
        <b-row>
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-primary" block size="sm"
            @click="updateDetail(lessons.id)" v-show="!showUpdate">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <small>Editar</small>
          </b-button>
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-danger" block size="sm"
            v-b-modal.modal-delete-lesson>
            <feather-icon icon="ArchiveIcon" class="mr-50" />
            <small>Arquivar</small>
          </b-button>
        </b-row>
      </b-col>

      <!-- Modal delete -->
      <b-modal id="modal-delete-lesson" ok-variant="danger" modal-class="modal-danger" ref="modal-delete-lesson" centered
        title="Arquivar aula">
        <b-card-text>
          <b>Deseja arquivar esta aula?</b>
        </b-card-text>
        <template #modal-footer>
          <b-col>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-danger" block
              @click="deleteLesson">
              <feather-icon icon="ArchiveIcon" class="mr-50" />
              Arquivar
            </b-button>
          </b-col>
        </template>
      </b-modal>

      <!-- Right Col: Table -->
      <b-col lg="5">
        <table class="mt-2 mt-xl-0 w-75">
          <tr>
            <th class="pb-50">
              <feather-icon icon="PenToolIcon" class="mr-75" />
              <span class="font-weight-bold">Módulo:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ lessons.module != null ? lessons.module.name : "", }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UsersIcon" class="mr-75" />
              <span class="font-weight-bold">Competências:</span>
            </th>
            <td class="pb-50 text-capitalize">
              <span :key="indextr" v-for="(tr, indextr) in lessons.competences"> {{ tr.code }};</span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="ListIcon" class="mr-75" />
              <span class="font-weight-bold">Habilidades:</span>
            </th>
            <td class="pb-50">
              <span :key="indextr" v-for="(tr, indextr) in lessons.abilities"> {{ tr.code }};</span>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <td class="pb-50 text-capitalize text-right font-weight-bold">
              {{ lessons.name }}
            </td>
          </tr>
          <tr>
            <td class="pb-50 text-capitalize text-right">
              {{ lessons.objective }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <!-- Fim cabeçalho do card Editar aula -->

    <hr>
    
    <!-- Início da tabela que lista as atividades da aula -->
    <div v-show="!showUpdate">
      <b-row>
        <b-col md="7">
          <b-row>
            <b-col md="4">
              <b-form-group label="Exibir" label-for="h-exibir" label-cols-md="4" class="text-muted">
                <b-form-select size="sm" class="form-content" v-model="perPage" :options="perPageOptions"
                  :disabled="isLoading" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <div class="col-md">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input placeholder="Pesquisar" v-model="search" debounce="800" type="search" size="sm"
              :disabled="isLoading" />
          </b-input-group>
        </div>
        <b-col md="2">
          <b-button variant="relief-primary" v-b-toggle.sidebar-create-activities size="sm" block @click="clearFormStore">
            Nova atividade
          </b-button>
        </b-col>
      </b-row>
      <b-table-simple striped stacked="md" class="mt-2 responsive text-center" id="tableBook">
        <b-thead>
          <b-tr class="text-center">
            <b-th>#</b-th>
            <b-th>Atividades</b-th>
            <b-th>Fase da Espiral</b-th>
            <b-th>Tipo de resposta</b-th>
            <b-th>Ações</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in lessons.activities">
            <b-td>
              <b-button-group>
                <b-button disabled>
                  {{ tr.order }}
                </b-button>
                <b-form-input v-model="orderLesson" class="btn-icon" placeholder="Ordem"
                  @click="activity_id_order = tr.id" />
              </b-button-group>
            </b-td>
            <b-td v-html="tr.description.substr(0, 150)">
            </b-td>
            <b-td>
              {{ tr.phase.name }}
            </b-td>
            <b-td>
              {{ tr.response_type && tr.response_type.description }}
            </b-td>
            <b-td>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark" class="btn-icon rounded-circle"
                @click="showActivitiesUpdate(tr.id)" v-b-toggle.sidebar-create-activities>
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                @click="showActivities(tr.id)" v-b-modal.modal-delete>
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-row class="mt-1">
        <b-col md="10">
          <b-row>
            <b-col md="5" v-show="totalRows > 10">
              Exibindo {{ perPage }} de {{ totalRows }} registros
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2">
          <b-pagination @change="handlePageChange" v-model="currentPage" :per-page="perPage" :total-rows="totalRows"
            aria-controls="tableBook" class="mt-1" />
        </b-col>
      </b-row>
    </div>
    <!-- Fim da tabela que lista as atividades da aula -->


    <!-- Início do formulário de editar aula -->
    <div v-show="showUpdate">
      <h3 class="text-center mb-2">Editar Aula</h3>
      <b-row>
        <b-col md="6">
          <b-form-group label="Titulo:" label-for="i-titulo">
            <b-form-input id="i-Titulo" placeholder="Ex: 9º Ano" v-model="updateLesson.name" />
          </b-form-group>
          <b-form-group label="Objetivo:" label-for="i-categ">
            <b-form-textarea id="i-objetivo" placeholder="Informe o objetivo da aula" rows="4"
              v-model="updateLesson.objective" />
          </b-form-group>
          <b-form-group label="Módulo:" label-for="i-public">
            <b-form-select text-field="name" value-field="id" :options="modules" id="module"
              v-model="updateLesson.module_id" />
          </b-form-group>
          <b-form-group label="Video:" label-for="i-video">
            <b-form-input id="i-video" placeholder="Ex: www.youtube.com.br" v-model="updateLesson.video_url" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Competência BNCC:" label-for="i-edicao">
            <v-select size="lg" multiple label="code" :options="compentences" v-model="updateLesson.competences" />
          </b-form-group>
          <b-form-group label="Habilidades:" label-for="i-esbn">
            <v-select size="lg" multiple label="code" :options="abilities" v-model="updateLesson.abilities" />
          </b-form-group>
          <b-form-group label="Materiais necessários:" class="pl-1 pr-1">
            <b-overlay :show="isLoadingQuill" opacity="0.70" variant="transparent" rounded="sm">
              <quill-editor v-model="updateLesson.supplies_needed" :options="editorOption" ref="quillEdit1">
              </quill-editor>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-form-group label="Descrição:" label-for="i-description">
            <b-form-input id="i-description" placeholder="" v-model="support_materials.description" />
          </b-form-group>
          <b-form-group label="Material Complementar:" label-for="i-material-complementar">
            <b-form-file v-if="fileOrLink == true" placeholder="Selecione um arquivo" type="file" name="file"
              v-model="file" />
            <b-form-input v-if="fileOrLink == false" placeholder="Insira o link do material" v-model="url_support" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Tipo:" label-for="i-material-tipo" class="pl-1 pr-1">
            <b-row>
              <b-col lg="1">
                <b-form-checkbox checked="true" class="custom-control-primary" name="check-button" switch
                  v-model="fileOrLink">
                  <span class="switch-icon-left">
                    <feather-icon icon="PaperclipIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="Share2Icon" />
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="PaperclipIcon" />
                  Arquivo
                </span>
              </b-col>
              <b-col>
                <span class="switch-icon-right">
                  <feather-icon icon="Share2Icon" />
                  Link
                </span>
              </b-col>
            </b-row>
          </b-form-group>
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" class="mt-5" block size="sm"
            @click="storeSupportMaterial">
            <feather-icon icon="BoxIcon" class="mr-50" />
            <small>Incluir Material</small>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table-simple striped stacked="md" class="mt-2 responsive text-center" id="tableBook">
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome</b-th>
              <b-th>Material Complementar</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in supportLocalMaterials">
              <b-td>
                {{ tr.description }}
              </b-td>
              <b-td>
                <b-link :href="tr.url" target="_blank">Baixar arquivo</b-link>
              </b-td>
              <b-td>
                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  @click="deleteDataMaterial(tr.id)" v-b-modal.modal-delete-support>
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-modal id="modal-update" ok-variant="primary" modal-class="modal-primary" ref="modal-update" centered
          title="Atualizar Material Complementar">
          <b-card-text>
            <validation-observer ref="simpleRulesMaterial">
              <b-form-group label="Titulo do material:" label-for="i-quest" class="pl-1 pr-1">
                <validation-provider #default="{ errors }" name="titulo do meterial" rules="required">
                  <b-form-input id="i-material" v-model="updateSupportMaterials.name" placeholder="Material de robotica"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Autor:" label-for="i-quest" class="pl-1 pr-1">
                <validation-provider #default="{ errors }" name="Autor" rules="required">
                  <b-form-input id="i-quest" v-model="updateSupportMaterials.description" placeholder="Autor"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Material Complementar:" label-for="i-quest" class="pl-1 pr-1">
                <b-form-file placeholder="Arquivo" type="file" name="file" v-model="updateSupportMaterials.file" />
              </b-form-group>

            </validation-observer>
          </b-card-text>
          <template #modal-footer>
            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-primary" block
                @click="updateSupportMaterialFunction">
                Atualizar
              </b-button>
            </b-col>
          </template>
        </b-modal>
        <b-modal id="modal-delete-support" ok-variant="danger" modal-class="modal-danger" ref="modal-delete-support"
          centered title="Deletar">
          <b-card-text>
            Por favor, confirme a exclusão antes de prosseguir.
            <b-form-checkbox id="checkbox-1" @change="newDeleteStatusSupport" v-model="DeleteStatusSupport"
              name="checkbox-1" value="accepted">
              Concordo em excluir este material de apoio
            </b-form-checkbox>
          </b-card-text>
          <template #modal-footer>
            <b-col>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-danger" block
                :disabled="DeleteStatusSupport == false" @click="deleteSupportMaterial(idDeleteSupport)">
                Deletar
              </b-button>
            </b-col>
          </template>
        </b-modal>
      </b-row>


      <hr>
      <h3>Resultados da aula:</h3>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Tipo:" label-for="i-type" class="pl-1 pr-1">
            <b-form-select v-model="lessonResult.type" text-field="description" value-field="id"
              :options="optionsLessonResult" />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Nome:" label-for="i-lesson-resul-name">
            <b-form-input id="i-lesson-resul-name" placeholder="" v-model="lessonResult.name" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Resultado da aula:" label-for="i-lesson-resul-file">
            <b-form-file v-if="lessonResult.type != 'VIDEO' && lessonResult.type != 'URL'" @change="onFileLessonResultChange"
              placeholder="Selecione um arquivo" type="file" name="file" />
            <b-form-input v-else type="url" v-model="lessonResult.url" placeholder="Insira o link" />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" class="mt-2" block size="sm"
            @click="addLessonResult">
            <feather-icon icon="BoxIcon" class="mr-50" />
            <small>Incluir resultado da aula</small>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table-simple v-if="lessonResults.length > 0" striped stacked="md" class="mt-2 responsive text-center"
          id="tableBook">
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome</b-th>
              <b-th>Tipo</b-th>
              <b-th>URL</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in lessonResults">
              <b-td>
                {{ tr.name }}
              </b-td>
              <b-td>
                {{ optionsLessonResult.filter(e => e.id == tr.type.toUpperCase())[0].description }}
              </b-td>
              <b-td>
                {{ tr.url }}
              </b-td>
              <b-td>
                <b-link :href="tr.url" target="_blank"> 
                  <feather-icon 
                    v-if="tr.type == 'FILE' || tr.type == 'IMAGE'" 
                    icon="DownloadIcon" 
                  />
                  <feather-icon 
                    v-else 
                    icon="LinkIcon" 
                  />
                </b-link>
                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  @click="removeLessonResult(tr.id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block @click="updateLessons">
            <feather-icon icon="Edit2Icon" /> Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block @click="cancelUpdate">
            <feather-icon icon="XIcon" /> Cancelar
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- Fim do formulário de editar aula -->

    <!-- Início da sidebar para criar e editar as atividades da aula -->
    <b-sidebar id="sidebar-create-activities" width="750px" shadow right bg-variant="white" backdrop
      :title="this.idActivities === null ? 'Nova atividade' : 'Editar atividade'" ref="sidebar-create-activities">
      <div>
        <validation-observer ref="simpleRules">
          <b-form-group label="Selecione a fase da espiral:" label-for="i-phase" class="pl-1 pr-1">
            <validation-provider #default="{ errors }" name="fase da espiral" rules="required">
              <b-form-select text-field="name" value-field="id" :options="phases" id="module"
                v-model="activitiesStore.phase_id" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <input type="file" @change="uploadFunction" id="file" hidden />
          <b-form-group label="Descrição:" class="pl-1 pr-1">
            <b-overlay :show="isLoadingQuill" opacity="0.70" variant="transparent" rounded="sm">
              <quill-editor v-model="activitiesStore.description" :options="editorOption" @change="quillIndex('1')"
                ref="quillEdit1">
              </quill-editor>
            </b-overlay>
          </b-form-group>
          <b-form-group label="Duração da atividade (Min.):" label-for="i-quest" class="pl-1 pr-1"
            v-if="typeBook == 'teacher'">
            <b-form-input id="i-quest" placeholder="30" v-model="activitiesStore.duration" />
          </b-form-group>
          <b-form-group label="Selecione o tipo da questão:" label-for="i-type-quest" class="pl-1 pr-1"
            v-if="typeBook == 'student'">
            <b-form-select text-field="description" value-field="id" :options="optionsQuest"
              v-model="activitiesStore.response_type_id" @change="optionsResponse" />
          </b-form-group>
        </validation-observer>
        <div 
          v-if="activitiesStore.response_type_id == optionMultiple || activitiesStore.response_type_id == optionTrueOrFalse" 
          class="pl-1 pr-1">
          <!-- Copyable Line -->
          <b-row v-for="(item, index) in activitiesStore.activity_options" :id="item.id" :key="item.id">
            <b-col md="12">
              <!-- Description Multiple -->
              <b-form-group label='Alternativa' label-for="item-name">
                <b-form-input id="item-name" type="text" placeholder="Alternativa"
                  v-model="activitiesStore.activity_options[index].description" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Is Correct -->
              <b-form-group label='Correta?' label-for="item-name">
                <b-form-checkbox checked="false" class="custom-control-success" name="check-button" switch
                  v-model="activitiesStore.activity_options[index].is_correct">
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Delete Line -->
            <b-col v-if="index != 0">
              <b-button @click="removeInput(index)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger">
                <feather-icon icon="XIcon" /> Apagar
              </b-button>
            </b-col>
          </b-row>

          <!-- Add Line -->
          <b-row class="pl-1 pr-1">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-dark" @click="addInput" block>
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Adicionar Alternativa</span>
            </b-button>
          </b-row>
        </div>
        <hr>
        </b-form>
        </b-overlay>
      </div>
      </div>
      <b-row class="p-1">
        <b-col lg="5" sm="6" class="mr-1">
          <!-- Save -->
          <b-button block variant="relief-primary" :disabled="activitiesStore.description == null" @click="storeActivity">
            <!-- Salvar -->
            {{ idActivities === null ? 'Salvar' : 'Editar' }}
          </b-button>
        </b-col>
        <b-col>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="closeForm()" block>
            Fechar
          </b-button>
        </b-col>
      </b-row>
    </b-sidebar>
    <!-- Fim da sidebar para criar e editar as atividades da aula -->

    <!-- Início do modal para exibir os materiais de apoio -->
    <b-modal id="modal-show-support" ref="modal-show-support" cancel-variant="outline-secondary" cancel-title="Fechar"
      centered title="Materiais adicionados">
      <p>
        <b-row :key="indextr" v-for="(tr, indextr) in supportLocalMaterials">
          <b-col md="9">
            {{ tr.name }}
          </b-col>
          <b-col>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
              @click="deleteSupport(tr.id, indextr)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <!-- Início do modal para exibir os materiais de apoio -->

    <!-- Início do modal de excluir atividade -->
    <b-modal id="modal-delete" ok-variant="danger" modal-class="modal-danger" ref="modal-delete" centered title="Deletar"
      v-if="activitiesModel != null">
      <b-card-text>
        <p>Você está preste a deletar esta atividade da aula: <b>{{ activitiesModel.description }}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox id="checkbox-1" @change="newDeleteStatus" v-model="DeleteStatus" name="checkbox-1"
          value="accepted" unchecked-value="null">
          Concordo em excluir esta atividade
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="outline-danger" block
            :disabled="DeleteStatus == null" @click="deleteActivity(activitiesModel.id)">
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Fim do modal de excluir atividade -->
    
    <!-- </b-overlay> -->
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import axios from "@axios";
import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  components: {
    BCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,

      DeleteStatus: null,
      DeleteStatusSupport: false,
      activitiesModel: null,

      showUpdate: false,
      lessonDetails: null,

      orderLesson: null,

      fileOrLinkUpload: true,

      updateSupportMaterials: {
        id: null,
        file: undefined,
        name: null,
        description: null
      },

      updateLesson: {
        id: null,
        name: null,
        objective: null,
        module_id: null,
        abilities: null,
        competences: null,
        video_url: null,
        support_materials: [],
        supplies_needed: null
      },

      support_material: null,
      updateModel: null,
      id: 1,

      idActivities: null,
      activitiesStore: {
        response_type_id: 'caa6b7a5-521c-4b0c-abf1-cafdef306732',
        activity_options: [],
        description: null,
        phase_id: null,
        support_materials: [],
      },

      activity_id_order: null,

      supportLocalMaterials: [{
        id: 999,
        description: 'val.description',
      }],
      support_materials: {
        description: null,
      },
      lessonResult: {
        name: null,
        type: 'VIDEO',
        file: null,
        url: null,
      },

      file: null,
      url_support: null,

      idDeleteSupport: null,

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      optionsQuest: [],
      optionsLessonResult: [
        {
          id: 'FILE',
          description: 'Arquivo',
        },
        {
          id: 'VIDEO',
          description: 'Vídeo',
        },
        {
          id: 'IMAGE',
          description: 'Imagem',
        },
        {
          id: 'URL',
          description: 'URL',
        },
      ],
      optionMultiple: null,
      optionTrueOrFalse: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      editorOption: {
        placeholder: 'Insira o texto aqui',
      },
      editorOption2: {
        modules: {
          toolbar: '#toolbar',
        },
      },

      indexQuill: null,

      isLoadingQuill: false,

      fileOrLink: true,

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['image', 'link'],
            ],
            handlers: {
              image: function () {
                document.getElementById('file').click()
              }
            }
          }
        }
      },
      content: null,

      typeBook: null,
    }
  },
  computed: {
    ...mapState("lessons", ["lessons"]),
    ...mapState("compentences", ["compentences"]),
    ...mapState("modules", ["modules"]),
    ...mapState("abilities", ["abilities"]),
    ...mapState("phases", ["phases"]),
    ...mapState("activities", ["activities"]),
    ...mapState("responseType", ["responseType"]),
    ...mapState("supportMaterials", ["supportMaterials"]),
    ...mapState("lessonResults", ["lessonResults"]),
  },
  created() {
    this.showLesson()
    this.fetchCompentences()
    this.fetchAbility()
    this.fetchModules()
    this.fetchPhases();
    this.fetchResponseType();
    if (!this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
    this.TypeBookTeacherOrStudent();
  },
  methods: {
    ...mapActions("lessons", ["fetchDetails", "update"]),
    ...mapActions("supportMaterials", ["store", "delete"]),

    quillIndex(value) {
      this.indexQuill = value
    },

    newOrderDown(id, order) {
      let new_order = ++order;
      this.reorderLessons(id, new_order);
    },

    newOrderUp(id, order) {
      let new_order = --order;
      this.reorderLessons(id, new_order);
    },

    reorderLessons(id, new_order) {
      this.isLoading = true
      this.$store
        .dispatch(`lessons/reorderLessonActive`, {
          activity_id: id,
          new_order: new_order
        })
        .then((response) => {
          this.showLesson()
          this.totalRows = response.total
        })
        .finally(() => {
          this.isLoading = false
          this.orderLesson = null
          this.activity_id_order = null
        })
    },

    uploadFunction(e) {
      this.isLoadingQuill = true;
      this.selectedFile = e.target.files[0];
      var form = new FormData();
      form.append("image", this.selectedFile);

      axios.post('image-upload', form, {
        'headers': {
          'Content-Type': "multipart/form-data"
        }
      })
        .then(r => {
          if (this.indexQuill == '1') {
            const range = this.$refs.quillEdit1.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit1.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '2') {
            const range = this.$refs.quillEdit2.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit2.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '3') {
            const range = this.$refs.quillEdit3.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit3.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '4') {
            const range = this.$refs.quillEdit4.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit4.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '5') {
            const range = this.$refs.quillEdit5.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit5.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '6') {
            const range = this.$refs.quillEdit6.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit6.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '7') {
            const range = this.$refs.quillEdit7.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit7.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          if (this.indexQuill == '8') {
            const range = this.$refs.quillEdit8.$refs.editor.__quill.selection.savedRange

            this.$refs.quillEdit8.$refs.editor.__quill.insertEmbed(range.index, 'image', r.data)
          }
          this.isLoadingQuill = false
        })
        .catch(e => {
          this.isLoadingQuill = false
        });
    },

    editDataMaterial(value) {
      this.updateSupportMaterials.id = value.id
      this.updateSupportMaterials.description = value.description,
      this.updateSupportMaterials.file = value.file
    },

    deleteDataMaterial(id) {
      this.idDeleteSupport = id
    },

    newDeleteStatusSupport(value) {
      this.DeleteStatusSupport = value
    },

    updateSupportMaterialFunction() {
      this.$refs.simpleRulesMaterial.validate().then(success => {
        if (success) {
          this.$store
            .dispatch("lessons/updateSupportMaterial", {
              data: this.updateSupportMaterials
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                },
              })
              this.$nextTick(() => this.$refs.simpleRulesMaterial.reset());
            })
            .finally(() => {
              this.showLesson()
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })

    },

    deleteSupportMaterial(id) {
      let data = id
      this.$store.dispatch("lessons/deleteSupportMaterial", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluido com sucesso!',
              icon: 'ArchiveIcon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.$refs['modal-delete-support'].hide()
          this.showLesson()
        })
        .finally(() => {
          // this.$router.push("/livros")
        })
    },
    addLessonResult() {
      this.$store.dispatch("lessonResults/store", {
        ...this.lessonResult,
        lesson_id: this.updateLesson.id
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })
        this.lessonResult = {
          name: null,
          type: 'VIDEO',
          file: null,
          url: null,
        }
      }).finally(() =>{
        this.isLoadingStore = false
      });
    },
    removeLessonResult(id) {
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("lessonResults/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
      })
      .catch(err => { 
        console.error(err); 
      })
    },
    onFileLessonResultChange(event){
      var fileData =  event.target.files[0];
      this.lessonResult.file=fileData;
      this.lessonResult.url=fileData.name;
    },

    TypeBookTeacherOrStudent() {
      this.typeBook = localStorage.getItem("typeBook")
    },

    optionsResponse(value) {
      if (value != this.optionMultiple && value != this.optionTrueOrFalse) {
        this.activitiesStore.activity_options = []
      }
    },

    backBookDetails() {
      let id = localStorage.getItem("book_id")
      this.$router.push("/livro/detalhe/" + id)
    },

    showLesson() {
      this.isLoading = true
      this.$store
        .dispatch("lessons/fetchDetails", {
          lesson_id: this.$route.params.id_lesson,
          filterActivities: this.search
        })
        .then((response) => {
          this.supportLocalMaterials = response.supportMaterials
          response.supportMaterials.forEach((value, index) => {
            this.updateLesson.support_materials.push(value.id)
          })
          this.lessonDetails = response
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    updateDetail(id) {
      this.$store
        .dispatch("lessons/fetchDetails", {
          lesson_id: this.$route.params.id_lesson
        })
        .then((response) => {
          this.updateLesson.support_materials = []
          this.showUpdate = true
          this.updateLesson.id = response.id
          this.updateLesson.name = response.name
          this.updateLesson.objective = response.objective
          this.updateLesson.supplies_needed = response.supplies_needed
          this.updateLesson.module_id = response.module_id
          this.updateLesson.abilities = response.abilities
          this.updateLesson.video_url = response.video_url
          this.updateLesson.competences = response.competences
          response.supportMaterials.forEach((value, index) => {
            this.updateLesson.support_materials.push(value.id)
          })
        })
    },

    deleteLesson(id) {
      let data = this.$route.params.id_lesson
      this.$store.dispatch("lessons/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Arquivado com sucesso!',
              icon: 'ArchiveIcon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
        })
    },

    showActivities(id) {
      this.$store
        .dispatch("activities/fetchDetails", {
          activity_id: id
        })
        .then((response) => {
          this.activitiesModel = response
        })
        .finally(() => {
        })
    },

    showActivitiesUpdate(id) {
      this.$store
        .dispatch("activities/fetchDetails", {
          activity_id: id
        })
        .then((response) => {
          this.idActivities = response.id;
          this.activitiesStore.response_type_id = response.response_type_id;
          this.activitiesStore.description = response.description;
          this.activitiesStore.phase_id = response.phase_id;

          this.activitiesStore.note = response.note;
          this.activitiesStore.duration = response.duration;
          this.activitiesStore.general_view = response.general_view;
          this.activitiesStore.pedagogical_objectives = response.pedagogical_objectives;
          this.activitiesStore.necessary_materials = response.necessary_materials;
          this.activitiesStore.preparing_the_activity = response.preparing_the_activity;
          this.activitiesStore.introduction = response.introduction;
          this.activitiesStore.development = response.development;
          this.activitiesStore.additional_information = response.additional_information;

          this.activitiesStore.activity_options = response.activity_options;
          this.supportLocalMaterials = response.support_materials;

          if (this.activitiesStore.activity_options.length > 0) {
            this.activitiesStore.activity_options.forEach((value, index) => {
              if (this.activitiesStore.activity_options[index].is_correct == 1) {
                this.activitiesStore.activity_options[index].is_correct = true
              } else {
                this.activitiesStore.activity_options[index].is_correct = false
              }
            })
          }
        })
        .finally(() => {
        })
    },

    newDeleteStatus(value) {
      this.DeleteStatus = value
    },

    deleteActivity(id) {
      let data = id
      this.$store
        .dispatch("activities/delete", data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
        })
        .finally(() => {
          this.DeleteStatus = null
          this.showLesson()
          this.$refs['modal-delete'].hide()
        })
    },

    storeSupportMaterial() {
      this.isLoadingStore = true
      if (this.fileOrLink == true) {
        this.support_material = {
          description: this.support_materials.description,
          file: this.file
        }
      } else {
        this.support_material = {
          description: this.support_materials.description,
          link: this.url_support,
        }
      }
      this.store({
        ...this.support_material,
      })
        .then((val) => {
          this.activitiesStore.support_materials.push({
            id: val.id,
          })
          this.supportLocalMaterials.push({
            id: val.id,
            description: val.description,
          })
          this.updateLesson.support_materials.push(val.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Material adicionado com sucesso!',
              icon: 'BookIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            },
          })

          this.support_materials = []
          this.file = null
          this.url_support = null
        })
        .finally(() => {
          this.isLoadingStore = false
        });
    },

    deleteSupport(id, index) {
      let data = id
      this.delete(
        data
      )
        .then(() => {
          this.supportLocalMaterials.splice(index, 1)
          this.activitiesStore.support_materials.splice(index, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            },
          })
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {

        });
    },

    closeForm() {
      this.$refs['sidebar-create-activities'].hide()
    },

    storeActivity(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.idActivities == null) {
            this.$store.dispatch("activities/store", {
              ...this.activitiesStore,
              lesson_id: this.$route.params.id_lesson
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cadastrado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })

                this.activitiesStore.description = null
                this.activitiesStore.response_type_id = 'caa6b7a5-521c-4b0c-abf1-cafdef306732',
                  this.activitiesStore.activity_options = []
                this.activitiesStore.phase_id = null
                this.activitiesStore.support_materials = []
                this.idActivities = null

                this.support_materials = []
                this.supportLocalMaterials = []

                this.$nextTick(() => this.$refs.simpleRules.reset());
              })
              .finally(() => {
                this.showLesson()
                this.$refs['sidebar-create-activities'].hide()
              })
          } else {
            this.$store.dispatch("activities/update", {
              ...this.activitiesStore,
              lesson_id: this.$route.params.id_lesson,
              id: this.idActivities
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Atualizado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })

                this.activitiesStore.description = null
                this.activitiesStore.response_type_id = 'caa6b7a5-521c-4b0c-abf1-cafdef306732',
                  this.activitiesStore.activity_options = []
                this.activitiesStore.phase_id = null
                this.activitiesStore.support_materials = []
                this.idActivities = null

                this.support_materials = []
                this.supportLocalMaterials = []

                this.$nextTick(() => this.$refs.simpleRules.reset());
              })
              .finally(() => {
                this.$refs['sidebar-create-activities'].hide()
                this.showLesson()
              })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchResponseType() {
      this.$store
        .dispatch("responseType/fetchResponseType")
        .then((response) => {
          this.optionsQuest = response;
          this.optionsQuest.forEach((value, index) => {

            if (value.description == "Múltipla escolha") {
              this.optionMultiple = value.id;
            }
            if (value.description == "Verdadeiro ou Falso") {
              this.optionTrueOrFalse = value.id;
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
        })
    },

    fetchPhases(paginated = false) {
      this.$store
        .dispatch("phases/fetchPhases", {
          paginate: paginated,
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
        })
    },

    clearFormStore() {
      this.idActivities = null
      this.activitiesStore.description = null
      this.activitiesStore.response_type_id = 'caa6b7a5-521c-4b0c-abf1-cafdef306732',
        this.activitiesStore.activity_options = [],
        this.activitiesStore.phase_id = null,
        this.activitiesStore.support_materials = [],
        this.activitiesStore.lesson_results = [],
        this.activitiesStore.note = null,
        this.activitiesStore.duration = null,
        this.activitiesStore.general_view = null,
        this.activitiesStore.pedagogical_objectives = null,
        this.activitiesStore.necessary_materials = null,
        this.activitiesStore.preparing_the_activity = null,
        this.activitiesStore.introduction = null,
        this.activitiesStore.development = null,
        this.activitiesStore.additional_information = null

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },

    fetchCompentences() {
      this.$store
        .dispatch("compentences/fetchCompentence", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    fetchAbility() {
      this.$store
        .dispatch("abilities/fetchAbility", {
          simple_list: true
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    fetchModules(paginated = false) {
      this.isLoading = true;
      this.$store
        .dispatch("modules/fetchModules", {
          paginate: paginated,
        })
        .then((response) => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    updateLessons() {
      this.update({
        ...this.updateLesson
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Atualizado com sucesso!',
              icon: 'ThumbsUpIcon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            },
          })
        })
        .finally(() => {
          this.showUpdate = false
          this.showLesson()
        });
    },

    cancelUpdate() {
      this.showUpdate = false
    },

    handlePageChange(value) {
    },

    addInput() {
      if (!this.activitiesStore.activity_options) {
        this.activitiesStore.activity_options.push({
          id: 1,
          description: null,
          is_correct: false
        })
      } else {
        this.activitiesStore.activity_options.push({
          id: this.id += this.id,
          description: null,
          is_correct: false,
        })
      }
    },

    removeInput(value) {
      this.activitiesStore.activity_options.splice(value, 1)
    },
  },
  watch: {
    perPage() {
    }
    ,
    search() {
      this.currentPage = 1;
      this.showLesson(true, 1);
    },
    orderLesson(value) {
      if (value)
        this.reorderLessons(this.activity_id_order, value)
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
